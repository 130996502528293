import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styles from "./hero.module.scss"

export default props => {
  return (
    <div className={styles.heroWrapper}>
      <Img fluid={props.bg} className={styles.backgroundImage} />

      <div className={styles.overlay}>
        <div className={"container-sm " + styles.flexContainer}>
          <div className={styles.heroContent}>
            <h1>{props.text_1}</h1>
            <p className="text-uppercase">{props.text_2}</p>
            <Link to={props.link}>
              <button className="btn btn-md btn-outline-light">
                Saber más
              </button>
            </Link>
            <Link to="/contacto/">
              <button className="btn btn-md btn-primary ml-3">
                Contacto
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
