import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"

export default props => {
  return (
    <section
      style={{
        backgroundColor: "#eeeeee",
        paddingTop: "7rem",
        paddingBottom: "7rem",
      }}
    >
      <div className="container-sm">
        <h1 className="mb-5">Nuestra Firma</h1>
        <p className="text-dark">
          <strong>GUERRERO & ASOCIADOS</strong>, es un estudio de abogados
          boutique de redes internacionales, que presta servicios a compañías e
          inversionistas nacionales y extranjeros.
        </p>
        <p>
          Nuestra firma, de valores tradicionales, presenta una propuesta
          diferenciada y novedosa, al establecer relaciones de largo horizonte
          con nuestros clientes, sustentada en un trato humano y personalizado,
          permitiendo colaborar con la alta administración con una mirada
          complementaria al otorgar las implicancias legales y tributarias en
          las decisiones, lo que fortalece el buen Gobierno Corporativo y la
          creación de valor superior a largo plazo.
        </p>
        <div style={{ maxWidth: `500px`, marginBottom: `1.45rem` }}></div>
        <Link to="/nosotros/">
          <button className="btn btn-outline-primary">Más sobre nosotros</button>
        </Link>
      </div>
    </section>
  )
}
