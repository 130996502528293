import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Carousel } from "react-bootstrap"
import Hero from "../components/hero"

export default () => {
  const { data } = useStaticQuery(graphql`
    query SlidesQuery {
      data: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/slides/" } }
        sort: { fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            frontmatter {
              link
              text_1
              text_2
              bg {
                childImageSharp {
                  fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const slides = data.edges.map(({ node }) => node.frontmatter)
  return (
    <section>
      <Carousel fade controls={false} pauseOnHover={false} interval={4000}>
        {slides.map(slide => (
          <Carousel.Item>
            <Hero
              bg={slide.bg.childImageSharp.fluid}
              text_1={slide.text_1}
              text_2={slide.text_2}
              link={slide.link}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  )
}
