import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styles from "./areas-de-practica-cards.module.scss"

export default props => {
  const { data } = useStaticQuery(graphql`
    query AreasQuery {
      data: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//areas-de-practica//" } }
        sort: { fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            excerpt
            frontmatter {
              title
              subtitle
              icon
            }
            parent {
              ... on File {
                id
                name
              }
            }
          }
        }
      }
    }
  `)

  const areas = data.edges.map(({ node }) => ({
    slug: node.parent.name,
    excerpt: node.excerpt,
    ...node.frontmatter,
  }))
  const setHash = function(slug){
    window.location.hash = slug
  }
  return (
    <section
      id="areas-de-practica"
      className={styles.section + " container-xl"}
    >
      <h1 className="mb-5">Áreas de Práctica</h1>
      <div className={styles.flexContainer}>
        {areas.map(area => (
          <div className={styles.flexItem + " text-center"}>
            <i className={area.icon + " fa-3x mb-2 " + styles.icon}></i>
            <p className="font-weight-bolder">{area.title}</p>
            <p>{area.subtitle}</p>
            <Link to={"/areas-de-practica#" + area.slug}>
              <button className="btn btn-link text-primary font-weight-bold" onClick={() => setHash(area.slug)}>
                Ver más
              </button>
            </Link>
          </div>
        ))}
      </div>
    </section>
  )
}
