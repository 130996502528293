import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NavbarTop from "../components/navbar-top"

import Slider from "../sections/hero-slider"
import NosotrosResumen from "../sections/nosotros-resumen"
import AreasDePractica from "../sections/areas-de-practica-cards"

const IndexPage = () => (
  <Layout>
    <SEO title="Guerrero y Asociados" />
    <NavbarTop hero />
    <Slider />
    <NosotrosResumen />
    <AreasDePractica/>
  </Layout>
)

export default IndexPage
